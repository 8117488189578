.start-screen input {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 22px);
  border: 2px solid #4b0082;
  border-radius: 5px;
  font-size: 16px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #4b0082;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #9370db;
}

.game-screen {
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box; 
}

/* Nueva clase para la puntuación */
.score-text {
  color: #ffffff; 
  font-weight: bold; 
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  width: 100%;
  justify-items: center;
  margin-top: 20px;
}

.card {
  width: 120px;
  height: 160px;
  perspective: 1000px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: opacity 0.5s ease;
}

.fade-out {
  opacity: 0; 
  pointer-events: none; 
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.card-inner.flipped {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.card-front img,
.card-back img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card-back {
  transform: rotateY(180deg);
}

.invisible {
  visibility: hidden; 
}

.game-over button {
  display: block;
  margin: 10px auto;
}

/* Media Queries para pantallas más pequeñas */
@media (min-width: 1024px) {
  .game-screen {
    width: 65vw; 
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .card-grid {
    gap: 10px;
  }

  .card {
    max-width: 100px;
  }
}

@media (max-width: 480px) {
  .card-grid {
    gap: 4px;
  }

  .card {
    max-width: 80px; 
  }

  .start-screen input, .game-over button {
    width: 90%;
  }
}

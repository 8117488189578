body {
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: #f0f8ff;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.start-screen input {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 22px);
  border: 2px solid #4b0082;
  border-radius: 5px;
  font-size: 16px;
}

.current-sequence {
  color: #ffffff; 
  font-weight: bold; 
  margin-top: 10px; 
}


button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #4b0082;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #9370db;
}

.game-screen {
  width: 100%; 
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box; 
}

.color-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.color-button {
  width: 80px;
  height: 80px;
  border-radius: 50%; 
  border: none;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.color-button:active {
  transform: scale(0.9); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.game-over button {
  display: block;
  margin: 10px auto;
}

/* Media Queries para pantallas grandes */
@media (min-width: 1024px) {
  .game-screen {
    width: 100vw; 
    margin: 0;
    padding: 0;
  }
}

/* Media Queries para pantallas medianas */
@media (max-width: 768px) {
  .color-buttons {
    gap: 10px;
  }

  .color-button {
    width: 60px;
    height: 60px; 
  }
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 480px) {
  .color-buttons {
    gap: 8px;
  }

  .color-button {
    width: 50px;
    height: 50px; 
  }

  .start-screen input, .game-over button {
    width: 90%; 
  }
}

body {
  font-family: 'Comic Sans MS', cursive, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #add8e6, #00008b);
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; 
  background: linear-gradient(to bottom, #add8e6, #00008b);
}

.App-header {
  width: 100%;
  background-color: rgba(201, 166, 10, 0.8); 
  padding: 15px 0;
  font-size: 20px;
  color: #4b0082;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.game-selection {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  width: 100%; 
  padding: 0 10px;
}

.game-card {
  width: 250px;
  height: 350px;
  background-color: #fff;
  border: 2px solid #4b0082;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
}

.game-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.game-card img {
  width: 100%;
  height: auto;
  border-bottom: 2px solid #4b0082;
}

.game-card h2 {
  margin: 10px 0;
  color: #4b0082;
  font-size: 18px;
}

/* Media Queries para pantallas más pequeñas */
@media (max-width: 768px) {
  .game-card {
    width: 200px;
    height: 280px;
  }

  .App-header {
    font-size: 18px;
    padding: 10px 0;
  }
}

@media (max-width: 480px) {
  .game-card {
    width: 150px;
    height: 210px;
  }

  .App-header {
    font-size: 16px;
  }
}
